import React from "react"
import Map from "../images/cdp-map.png"
import Twitter from "../images/twitter.png"
import LinkedIn from "../images/linkedin.png"

const HomeContacto = () => (
  <div className="w-full mx-auto mt-12 mb-0 bg-gray-900 py-8 lg:py-12 lg:px-12">
    <h1 className="text-3xl text-gray-200  pl-5 lg:pl-0">Contacto</h1>

    <div className="w-full lg:flex mt-8 text-sm">
      <div className="w-full lg:w-1/3  pl-5 lg:pl-0">
        <p className="text-gray-400">
          <b>Email:</b>{" "}
          <a href="mailto:mlechandi@cdp.legal">mlechandi@cdp.legal</a>
        </p>
        <p className="text-gray-400">
          <b>Tel:</b> <a href="tel:+50622806282">(506) 2280-6282</a>
        </p>
        <p className="text-gray-400">
          <b>Apdo Postal:</b> 2430-2050
        </p>
        <p className="text-gray-400">
          <a
            href="https://twitter.com/echandi_maria"
            target="_blank"
            rel="noreferrer"
            style={{
              backgroundRepeat: "no-repeat",
              backgroundImage: `url(${Twitter})`,
              backgroundSize: `25px,25px`,
              height: "29px",
              display: "block",
              paddingLeft: "30px",
              outline: "none",
            }}
          >
            Twitter
          </a>
        </p>

        <p className="text-gray-400">
          <a
            href="https://www.linkedin.com/in/mar%C3%ADa-lourdes-echandi-gurdi%C3%A1n-33473544/"
            target="_blank"
            rel="noreferrer"
            style={{
              backgroundRepeat: "no-repeat",
              backgroundImage: `url(${LinkedIn})`,
              backgroundSize: `23px,23px`,
              height: "25px",
              display: "block",
              paddingLeft: "30px",
              outline: "none",
            }}
          >
            LinkedIn
          </a>
        </p>
      </div>

      <div className="w-full lg:w-1/3">
        <h5 className="uppercase text-sm text-gray-400 mb-3 mt-12 lg:mt-0  pl-5 lg:pl-0">
          Dirección
        </h5>
        <a href="https://www.waze.com/ul?place=ChIJuR56jXjjoI8RHM_ucfU9kQg&ll=9.93093760%2C-84.06016000&navigate=yes">
          <img src={Map} alt="CDP Mapa - Costa Rica" />
        </a>
      </div>

      <div className="w-full lg:w-1/3">
        <p className="text-sm text-gray-400 ml-5 mt-8">
          San José, Costa Rica. Barrio Los Yoses, calle 41 con avenida 8, San
          Pedro de Montes de Oca. Edificio esquinero.
        </p>
        <p className="text-sm text-gray-400 ml-5 mt-8">
          <a
            href="https://www.waze.com/ul?place=ChIJuR56jXjjoI8RHM_ucfU9kQg&ll=9.93093760%2C-84.06016000&navigate=yes"
            className="underline"
          >
            Abrir en Waze
          </a>
        </p>
      </div>
    </div>
  </div>
)

export default HomeContacto
